<template>
  <div class='reset-password'>
    <layout-header />
    <main class='reset-password__main'>
      <section
        class='reset-password__main__modal'
        v-if='!this.successSendMailFlg'
      >
        <h3>パスワードを変更します</h3>
        <p>再設定用のメールを送るメールアドレスを入力してください</p>
        <div>
          <div>
            <input
              type="email"
              v-model="email.value"
              @keyup="validEmail"
              placeholder="メールアドレスを入力"
            />
            <p v-for='message in email.valid.message' :key='message.key'>{{ message }}</p>
          </div>
          <button-normal
            @emitClick='resetPassword()'
            :loading='isLoading.resetPassword'
            :disabled='judgeDisabledResetPassword'
          >
            <p>メールを送信する</p>
          </button-normal>
        </div>
      </section>
      <section
        class='reset-password__main__modal'
        v-if='this.successSendMailFlg'
      >
        <p>メールを送信しました<br>メールよりパスワードの再設定を行ってください</p>
      </section>
      <notifications group="top-right"/>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import axios from 'axios';
import '../firebaseConfig';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
  },
  data() {
    return {
      successSendMailFlg: false,
      isLoading: {
        resetPassword: false,
      },
      email: {
        value: '',
        field: 'email',
        valid: {
          message: [],
          rule: {
          },
        },
      },
    };
  },
  computed: {
    judgeDisabledResetPassword() {
      return this.email.valid.message.length !== 0
        || this.email.value === '';
    },
  },
  methods: {
    async resetPassword() {
      this.validEmail();
      if (this.email.valid.message.length === 0) {
        this.isLoading.resetPassword = true;
        await axios.post(
          `${process.env.VUE_APP_HOST}/api/login/sendPasswordResetLink`,
          { email: this.email.value },
        )
          .then(() => {
            this.successSendMailFlg = true;
            this.isLoading.resetPassword = false;
          })
          .catch((e) => {
            this.errorFunc(
              'メール送信に失敗しました',
              e.response.data.error,
            );
            this.isLoading.resetPassword = false;
          });
      }
    },
    errorFunc(title, text = 'しばらく時間を置いてからお試しください') {
      this.$notify({
        title,
        text,
        speed: 400,
        type: 'error',
        group: 'top-right',
      });
    },
    validEmail() {
      const target = this.email.value;
      const valid = [];
      valid.push(this.checkEmailFormat(this.email.field, target));
      this.email.valid.message = valid.filter((v) => v !== '');
    },
    checkEmailFormat(field, target) {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]*\.[A-Za-z0-9]+$/;
      if (!target.match(reg)) {
        return `${field}はメール形式で入力する必要があります`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.reset-password {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.reset-password__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.reset-password__main__modal {
  background-color: $color-white;
  width: 500px;
  text-align: center;
  padding: 30px 30px 35px;
  h3 {
    font-size: 24px;
  }
  >p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
  div {
    div {
      margin-bottom: 20px;
      input {
        padding: 10px;
        outline: none;
        background-color: $color-gray;
        border: none;
        border-radius: 8px;
        width: 300px;
      }
      p {
        color: $color-red;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
@media screen and (max-width: 650px) {
.reset-password__main__modal {
  div div input {
    font-size: 16px;
  }
}
}
</style>
